import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridCell, GridColumn } from '@progress/kendo-react-grid';
import { FormComboBox, FormInput } from '../../../form/form-component';
import { useHistory } from 'react-router-dom';
import columns, { columnMenuConfig } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import {
  isColumnActive,
} from "../../../grid/CustomColumnMenu";
import LoadMask from '../../../../utils/LoadMask';
import { showToast } from '../../../../utils/Notification';
import { getJobId } from '../../../../store/actions/workOrder';
import { updateUserPreferenceAction } from '../../../../store/actions/commonActions';
import { searchUserPrefrence } from '../../../../store/actions/dashboard';
import { inputValidator } from '../../../form/validator';
import { filterBy, orderBy, Pager } from '@progress/kendo-react-data-tools';
import { exportReactiveReport, getReactiveGraphReport, getReactiveReport } from '../../../../store/actions/reports/GNIC/reactive';
import BarGraphData from './BarGraphData';
import { setOrderIndex } from '../../../../utils/Utility';
import useLogout, { checkInvalidSession } from '../../../../utils/useLogout';
import { MyPager } from '../../../generalHooks';
import Header from '../../../header';
import { getExportDataWithPostTimeoutHandler } from '../../eCollection/summaryReport/common/getSummaryReport';

const GRID_NAME = "NIC_REACTIVE_GRID"

const createDataState = (data, dataState) => {
  return {
    result: {
      data,
      take: dataState.take,
    },
    dataState: dataState,
  };
};

let initialState = createDataState([], {
  take: 50,
  skip: 0,
});


const initialColumnsState = setOrderIndex(columns)
const ReactiveReport = () => {
  const serverColumns = useSelector((state) => state.commonReducer.gridUserPrefrence);
  const dateStamp = useSelector((store) => store?.reports?.dateStamp);
  const locationData = useSelector((state) => state.commonReducer.locationData);
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleAuthContext } = useLogout();

  const [showForm, setShowForm] = useState(true)
  const [expandIcon, setExpandIcon] = useState("fullscreen");
  const [showGraphData, setShowGraphData] = useState(false)
  const [formEQ, setFormEQ] = useState([]);
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const [formWorkOrderId, setFormWorkOrderId] = useState([]);
  const [data, setData] = useState({ data: [], total: 0 });
  const [year, setYear] = useState([]);
  const [weekNumber, setWeekNumber] = useState([]);
  const [showLoader, setShowLoader] = useState(null);
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);

  const initialFormValue = useMemo(() => {
    // const result = locationData;
    return {
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country?.[0]?.CountryID,
      year: dateStamp?.selectedYear?.DisplayValue.toString(),
      week: dateStamp?.selectedWeek?.DisplayValue.toString(),
    };
  }, [locationData]);

  const [selectedData, setSelectedData] = useState(initialFormValue);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState([
    {
      field: "BatchId",
      dir: "asc",
    },
  ]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const expand = () => {
    setShowForm(!showForm);
    setExpandIcon(!showForm ? "fullscreen" : "fullscreen-exit")
  }

  useEffect(() => {
    setSelectedData({
      ...selectedData,
      Region: locationData?.Region?.RegionID,
      Country: locationData?.Country?.[0]?.CountryID,
      year: dateStamp?.selectedYear?.DisplayValue.toString(),
      week: dateStamp?.selectedWeek?.DisplayValue.toString(),
    });
  }, [locationData?.Country, dateStamp]);

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  const fetchJobId = async (params) => {
    // setShowLoader({
    //   msg: "loading",
    // });

    // const apiParams = {
    //   "GetJobIdList": params
    // }

    const res = await getJobId(JSON.stringify(params), authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (!res?.GetJobIdListRes?.JobIdList) {
      showToast("error", t(res?.GetJobIdListRes?.StatusMsg));
    }
    setFormEQ(res?.GetJobIdListRes?.JobIdList);
    setShowLoader(null);
  };

  useEffect(() => {
    const searchPlan = () => {
      let params = {
        // Region: selectedData.Region,
        Country: selectedData.Country,
        // CollectionType: "GNIC",
        Year: selectedData.year,
        Week: selectedData.week?.toString(),
        CompanyId: authContext.CompanyID,
      };


      const params2 = {
        "CollectionType": "GNIC",
        "ReportName": "ITEM_DETAILS_REPORT",
        "APIName": "getJobIdList",
        "Payload": {
          "GetJobIdList": {
            // Region: selectedData.Region,
            Country: selectedData.Country,
            // CollectionType: "GNIC",
            Year: selectedData.year,
            Week: selectedData.week?.toString(),
            CompanyId: authContext.CompanyID,
          }
        }
      }


      fetchJobId(params2);
    };
    searchPlan();
  }, []);

  const onChangeWeekNumber = async (e) => {
    onComboboxChange(e);

    const params2 = {
      "CollectionType": "GNIC",
      "ReportName": "REACTIVE_REPORT",
      "APIName": "getJobIdList",
      "Payload": {
        "GetJobIdList": {
          // Region: selectedData.Region,
          Country: selectedData.Country,
          // CollectionType: "GNIC",
          Year: selectedData.year,
          Week: selectedData.week?.toString(),
          CompanyId: authContext.CompanyID,
        }
      }
    }
    let params = {
      // Region: selectedData.Region,
      Country: selectedData.Country,
      // CollectionType: "GNIC",
      Year: selectedData.year,
      Week: e.value?.toString(),
      CompanyId: authContext.CompanyID,
    };

    fetchJobId(params2);
  };

  const handleCountryChange = (e) => {
    onComboboxChange(e);

    const params2 = {
      "CollectionType": "GNIC",
      "ReportName": "REACTIVE_REPORT",
      "APIName": "getJobIdList",
      "Payload": {
        // Region: selectedData.Region,
        Country: e.value,
        // CollectionType: "GNIC",
        Year: selectedData.year,
        Week: selectedData.week?.toString(),
        CompanyId: authContext.CompanyID,
      }
    }

    // let params = {
    //   // Region: selectedData.Region,
    //   Country: e.value,
    //   // CollectionType: "GNIC",
    //   Year: selectedData.year,
    //   Week: selectedData.week?.toString(),
    //   CompanyId: authContext.CompanyID,
    // };
    fetchJobId(params2);
  };

  useEffect(() => {
    let workOrder_id = [];
    let weekNumberData = [];
    let yearData = [];

    formEQ?.map((elm) => workOrder_id.push(elm));
    setFormWorkOrderId([...new Set(workOrder_id)]);

    dateStamp?.weekData?.map((elm) => weekNumberData.push(elm.DisplayValue));
    setWeekNumber([...new Set(weekNumberData)]);

    dateStamp?.yearData?.map((elm) => yearData.push(elm.DisplayValue));
    setYear([...new Set(yearData)]);
  }, [formEQ, dateStamp?.weekData, dateStamp?.yearData]);


  const onChange = (e) => {
    setSelectedData({
      ...selectedData,
      [e.target.name]: e.value,
    });
  };

  const onComboboxChange = (e) => {
    setSelectedData({
      ...selectedData,
      [e.target.component.name]: e?.value?.toString() ?? '',
    });
  };

  const fetchReactiveReport = async (newParams = {}) => {

    const params2 = {
      "CollectionType": "GNIC",
      "ReportName": "REACTIVE_REPORT",
      "APIName": "getReactiveReport",
      "Payload": {
        "ReactiveCodeReportRequest": {
          "FIlterParams": {
            "Region": selectedData.Region,
            "Country": selectedData.Country,
            "CollectionType": "GNIC",
            "Cycle": null,
            "Year": selectedData.year?.toString(),
            "Week": selectedData.week?.toString(),
            "FromDate": "",
            "ToDate": "",
            "WorkOrderID": selectedData.JobId ? selectedData.JobId : "",
            "CompanyId": authContext.CompanyID,
            "StoreNumber": selectedData.smsId ? selectedData.smsId : "",
            "AuditorId": selectedData.auditorId ? selectedData.auditorId : "",
            "StoreType": selectedData.plantype,
            "NavigatorAuditorID": selectedData.auditorId || "",
            "page": 1,
            "startCount": 0,
            "MaxCount": 50,
            ...newParams,
          }
        }
      }
    }



    // const params = {
    //   ReactiveCodeReportRequest: {
    //     FIlterParams: {
    //       Region: selectedData.Region,
    //       Country: selectedData.Country,
    //       CollectionType: "GNIC",
    //       Year: selectedData.year.toString(),
    //       Week: selectedData.week.toString(),
    //       StoreID: selectedData.smsId || "",
    //       CompanyID: authContext.CompanyID,
    //       NavigatorAuditorID: selectedData.auditorId || "",
    //       WorkOrderID: selectedData?.JobId || "",
    //     },
    //   },
    //   ...newParams
    // };


    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getReactiveReport(JSON.stringify(params2), authContext);
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.ReactiveCodeReportResponse?.StatusMsg === "Success" && Array.isArray(res?.ReactiveCodeReportResponse?.ReactiveDataList)) {
      res?.ReactiveCodeReportResponse.ReactiveDataList.map((cn) => {
        const realCountry = locationData?.Country.filter(({ CountryID }) => (CountryID === cn.Country))
        cn.Country = realCountry?.[0]?.CountryName
      });

      const result = {
        data: res?.ReactiveCodeReportResponse.ReactiveDataList,
        total: res?.ReactiveCodeReportResponse?.TotalCount,
      };
      setData(result);
    } else {
      const result = {
        data: [],
        total: 0,
      };
      setData(result);
      showToast(
        "error",
        t(res?.ReactiveCodeReportResponse?.StatusMsg || "failure")
      );
    }
    setShowLoader(null);
  }
  //On Search

  const onSearchClick = async (e) => {
    if (!e.isValid) {
      return;
    }
    if (e.values?.report === "Trends") {
      setShowGraphData(true);
      const params = {
        Request: JSON.stringify({
          ReactiveCodeReportRequest: {
            FIlterParams: {
              Region: selectedData.Region,
              Country: selectedData.Country,
              CollectionType: "GNIC",
              Year: selectedData.year.toString(),
              Week: selectedData.week.toString(),
              StoreID: selectedData.smsId || "",
              CompanyID: authContext.CompanyID,
              NavigatorAuditorID: selectedData.auditorId || "",
              WorkOrderID: selectedData?.JobId,
            },
          },
        }),
        page: 1,
        StartCount: 0,
        MaxCount: 50,
      };
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      const res = await getReactiveGraphReport(params, authContext);
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      setShowLoader(null);
    } else {
      setShowGraphData(false);
      fetchReactiveReport()
    }
  };

  const onExport = async () => {
    setShowLoader({
      // className: ".workOrders_grid",
      msg: "loading",
    });

    showToast(
      "info",
      t('filedownload_msglbl')
    );

    const params2 = {
      "CollectionType": "GNIC",
      "ReportName": "REACTIVE_REPORT",
      "APIName": "exportReactiveReport",
      "Payload": {
        "ReactiveCodeReportRequest": {
          "FIlterParams": {

            Region: selectedData?.Region,
            Country: selectedData?.Country,
            CollectionType: "GNIC",
            Year: selectedData?.year,
            Week: selectedData?.week,
            WorkOrderID: selectedData?.JobId,
            NavigatorAuditorID: selectedData?.auditorId,
            StoreID: selectedData?.smsId,
            CompanyID: authContext.CompanyID,

          }
        }

      }
    }

    const params = {
      ReactiveCodeReportRequest: {
        FIlterParams: {
          Region: selectedData?.Region,
          Country: selectedData?.Country,
          CollectionType: "GNIC",
          Year: selectedData?.year,
          Week: selectedData?.week,
          WorkOrderID: selectedData?.JobId,
          NavigatorAuditorID: selectedData?.auditorId,
          StoreID: selectedData?.smsId,
          CompanyID: authContext.CompanyID,
        },
      },




      // Request: JSON.stringify({
      //   ReactiveCodeReportRequest: {
      //     FIlterParams: {
      //       Region: selectedData?.Region,
      //       Country: selectedData?.Country,
      //       CollectionType: "GNIC",
      //       Year: selectedData?.year,
      //       Week: selectedData?.week,
      //       WorkOrderID: "",
      //       NavigatorAuditorID: selectedData?.auditorId,
      //       StoreID: selectedData?.smsId,
      //       CompanyID: authContext.CompanyID,
      //     },
      //   },
      // }),
    };
    // await exportReactiveReport(params, authContext);
    getExportDataWithPostTimeoutHandler(params2, authContext)
    setShowLoader(null);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext.SpocMail,
        Company_ID: authContext.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: selectedData.Country,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast(
        "success",
        t(res?.Response?.Message || "Success")
      );
    } else {
      showToast(
        "error",
        t(res?.Response?.Message || "dataNotFound")
      );
    }
    setShowLoader(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  const backToReports = () => {
    history.goBack();
  };

  const handlePageChange = async (e) => {
    const dataState = e.page;
    setDataState(dataState);
    fetchReactiveReport({
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
    });
  };

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          columns.forEach((col) => {
            if (col.filter === "numeric") {
              const dataValue = d[col.field]
              if (!isNaN(dataValue)) {
                d[col.field] = parseInt(dataValue);
              }
            }
            if (col.filter === "date") {
              const dateValue = new Date(d[col.field]);
              if (dateValue !== "Invalid Date") {
                d[col.field] = dateValue;
              }
            }
          })
          return d;
        }),
        ...data,
      });
    }
  }, [data]);

  const initialValues = {
    regionID: locationData?.Region?.RegionID,
    countryID: locationData?.Country?.[0]?.CountryID,
    year: dateStamp?.selectedYear,
    week: dateStamp?.selectedWeek,
  };

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <Pager style={{ flex: 1 }} {...props} />
    </MyPager>
  ), [stateColumns]);

  return (
    <>
      {showLoader && <LoadMask {...showLoader} />}

      <div className="eq">
        <Header activeMenu={t("nielsennavigator-reactivecodingreport")}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              marginInline: "auto 1em",
              padding: '6px'
            }}
          >
            <Button
              className='k-secondary'
              onClick={backToReports}
            >
              Back
            </Button>
          </div>
        </Header>
        {showForm ? (
          <div className="eqform">
            <Form
              initialValues={initialValues}
              onSubmitClick={onSearchClick}
              render={(formRenderProps) => (
                <FormElement>
                  <div
                    style={{
                      display: "flex",
                      gap: "0px 25px",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginBottom: "15px",
                    }}
                  >
                    <Field
                      name={"Region"}
                      component={FormComboBox}
                      label={t("REGION")}
                      required={true}
                      onChange={onComboboxChange}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      textField="RegionName"
                      valueField="RegionID"
                      data={[locationData?.Region]}
                      defaultValue={locationData?.Region?.RegionID}
                    />

                    <Field
                      name={"Country"}
                      component={FormComboBox}
                      label={t("COUNTRY")}
                      required={true}
                      onChange={handleCountryChange}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      textField="CountryName"
                      valueField="CountryID"
                      data={locationData?.Country || []}
                      defaultValue={locationData?.Country?.[0]?.CountryID}
                    />

                    <Field
                      name="year"
                      label={t("YEAR")}
                      component={FormComboBox}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("YEAR")}
                      data={year}
                      required={true}
                      defaultValue={dateStamp?.selectedYear?.DisplayValue}
                      validator={inputValidator}
                      onChange={onComboboxChange}
                    />

                    <Field
                      name="week"
                      label={t("WeekNumber")}
                      component={FormComboBox}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("WeekNumber")}
                      required={true}
                      data={weekNumber}
                      defaultValue={dateStamp?.selectedWeek?.DisplayValue}

                      validator={inputValidator}
                      onChange={onChangeWeekNumber}
                    />

                    <Field
                      name="JobId"
                      label="Job Id"
                      component={FormComboBox}
                      wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                      placeholder={"Job Id"}
                      data={formWorkOrderId}
                      onChange={onComboboxChange}
                    />

                    <Field
                      name="auditorId"
                      label={t("AUDITOR ID")}
                      component={FormInput}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("AUDITOR ID")}
                      onChange={onChange}
                    />

                    <Field
                      name="smsId"
                      label={t("storeid")}
                      component={FormInput}
                      wrapperStyle={{
                        width: "calc(25% - 25px)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      placeholder={t("storeid")}
                      onChange={onChange}
                    />

                    <Field
                      name="report"
                      label={t("Report")}
                      component={FormComboBox}
                      wrapperStyle={{ width: "calc(25% - 25px)", fontSize: "15px", fontWeight: "500" }}
                      placeholder={t("Report")}
                      data={["Metrics", "Trends"]}
                      defaultValue={["Metrics"]}
                      onChange={onComboboxChange}
                    />

                    <div
                      className={"k-actions k-hstack k-justify-content-stretch"}
                      style={{
                        marginTop: "29px",
                        gap: "0px 8px",
                        marginLeft: "-5px",
                      }}
                    >
                      <Button
                        className={'k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary'}
                        primary
                      >
                        {t("SEARCH")}
                      </Button>
                      <Button
                        type={'reset'}
                        className={'k-secondary'}
                        onClick={() => {
                          setSelectedData(initialFormValue);
                          setTimeout(() => {
                            formRenderProps.onFormReset();
                          }, 0);
                        }}
                      >
                        {t("RESET")}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        ) : (
          <div></div>
        )}
        {(showGraphData)
          ? <>
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <Button
                onClick={onExport}
                style={{ border: "none", marginLeft: 'auto' }}
                primary
              >
                Export
              </Button>
              <Button
                icon={expandIcon}
                onClick={expand}
                style={{ border: "none", margin: "10px 10px" }}
                primary
              ></Button>
            </div>
            <BarGraphData />
          </>

          :
          <div
            className="nielsen-card"
            style={{ display: "flex", flexDirection: "column", height: "100%", margin: "10px" }}
          >
            <div className="eq_data_heading" style={{ margin: "0px -6px" }}>
              <h3 style={{ marginLeft: "20px", fontWeight: "bold" }}>{t("searchlist")}</h3>
              <div>
                <Button
                  onClick={onExport}
                  style={{ margin: "10px", border: "none" }}
                  primary
                >{t("export")}</Button>

                <Button icon={expandIcon}
                  onClick={expand}
                  className={'k-secondary'}
                  style={{ margin: "10px", marginRight: '20px' }}
                ></Button>
              </div>

            </div>
            <div className="catalog" style={{
              display: "flex",
              padding: "1rem",
              flexGrow: 1,
              overflow: "auto",
            }}
            >
              <Grid
                className="workOrders_grid"
                data={result}
                {...dataState}
                onDataStateChange={dataStateChange}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  console.log("sort", { e });
                  setResult((result) => {
                    const { data, total } = result;
                    return {
                      data: orderBy(data || [], e.sort),
                      total,
                    };
                  });
                  setSort(e.sort);
                }}
                filter={filter}
                onFilterChange={(e) => {
                  console.log("filter", e.filter, data);
                  setResult((result) => {
                    const { total } = result;
                    return {
                      data: filterBy(data?.data || [], e.filter),
                      total,
                    };
                  });
                  setFilter(e.filter);
                }}
                pageable={{
                  pageSizes: false,
                }}
                pager={CreatePager}
                resizable
                onPageChange={handlePageChange}
                reorderable
                onColumnReorder={({ columns }) => {
                  const columnOrder = columns.reduce((columnsObj, column) => {
                    columnsObj[column.field] = column.orderIndex;
                    return columnsObj;
                  }, {})
                  const newColumns = stateColumns.map((column) => {
                    return { ...column, orderIndex: columnOrder[column.field] }
                  });
                  setStateColumns(newColumns);
                }}
              >

                {stateColumns.map((column, idx) => {
                  const menu = columnMenuConfig[column?.columnMenu];
                  return (
                    column.show && (
                      <GridColumn
                        key={idx}
                        field={column?.field}
                        title={t(column.title)}
                        width={column?.width}
                        minResizableWidth={30}
                        style={{ textAlign: "center" }}
                        cell={GridCell}
                        format={column?.format}
                        filter={column?.filter}
                        orderIndex={column?.orderIndex}
                        {...(menu && {
                          columnMenu: (props) => {
                            return menu?.({
                              ...props,
                              columns: stateColumns,
                              onColumnsSubmit,
                              data: data?.data,
                            });
                          },
                        })}
                        headerClassName={
                          isColumnActive(column.field, { ...dataState, filter })
                            ? "activeFiltered"
                            : ""
                        }
                      />
                    )
                  );
                })}
              </Grid>
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default ReactiveReport
